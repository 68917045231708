import { navigate } from "gatsby";
import React from "react";
import { Layout, SingleAgency } from "../components";
import { AgenciesSection } from "../style";

function Ride({ location = {} }) {
  if (location.state === null || undefined) {
    // console.log(location.state)
    navigate("/agencies");
  }

  //   console.log("agency from navigation", location.state);

  return (
    <div>
      <Layout>
        <AgenciesSection>
          <h1>
            Details of Travel Partner -{" "}
            {location.state ? location.state.data.id : " "}
          </h1>
          <SingleAgency agencyData={location.state && location.state.data} />
        </AgenciesSection>
      </Layout>
    </div>
  );
}

export default Ride;
